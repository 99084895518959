import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './registerServiceWorker'
import BootstrapVue from 'bootstrap-vue' 
import VueSweetalert2 from 'vue-sweetalert2';
import vSelect from 'vue-select'
import { LoaderPlugin } from 'vue-google-login'
import VeeValidate from 'vee-validate'
Vue.component('v-select', vSelect)

import cookie from 'js-cookie'
Vue.use(cookie);


import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics,{
  id: 'GTM-TMPM8NN'
})

Vue.use(LoaderPlugin, {
  client_id: '883629074374-38kqb0b9051qhi1l3bp2unujlsoqtr1h.apps.googleusercontent.com'
})
Vue.GoogleAuth.then(auth2 => {
  Vue.prototype.$authGoogle = auth2
  // console.log(auth2.isSignedIn.get())
})
// import bootstrap
Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
import 'bootstrap/dist/css/bootstrap.css' 
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css'
import '@/assets/scss/main.scss'
import 'slick-carousel/slick/slick.css'
import 'sweetalert2/dist/sweetalert2.min.css';

// validator

Vue.use(VeeValidate, {
  classes: true,
  classNames: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
})
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
Vue.use(VueMoment, {
    moment,
})

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)

// font
require('./assets/css/iconfont.css')
require('./assets/css/line-awesome-font-awesome.min.css')
// import tailwind
import '@/assets/css/tailwind.css'
 
import VueNumerals from 'vue-numerals';
Vue.use(VueNumerals);

if (cookie.getJSON('userdata') !== undefined) {
  let UserData = cookie.getJSON('userdata')
  store.commit('SET_LOGIN', UserData)
 
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vSelect,
  render: h => h(App)
}).$mount('#app')
