 

const mutations = { 
	SET_BG(state, val) {
		state.bgSetting = val
	},
	SET_LOGIN (state, payload) {
		state.userdata = payload
	}
}

export default mutations