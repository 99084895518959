import Vue from 'vue'
import Meta from "vue-meta";
import VueRouter from 'vue-router'

Vue.use(Meta);
Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
    path: '',
    component: () => import('@/views/main/main.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/views/Home.vue'),
        meta: {
          requireAuth: true

        }
      },  
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue'),
        meta: {
          requireAuth: true

        }
      },
      {
        path: '/berita',
        name: 'Berita',
        component: () => import('../views/pages/berita/list.vue'),
        meta: {
          requireAuth: true

        }
      },
      {
        path: '/panduan-umroh',
        name: 'panduan umroh',
        component: () => import('../views/pages/panduan-umroh/panduan-umroh.vue')
      },

      {
        path: '/doa-umroh/:id',
        name: 'doa umroh',
        component: () => import('../views/pages/panduan-umroh/doa-umroh.vue')
      },

      {
        path: '/peluang-usaha',
        name: 'peluang usaha',
        component: () => import('../views/pages/peluang-usaha.vue')
      },

      {
        path: '/tentang-kami',
        name: 'tentang kami',
        component: () => import('../views/pages/tentang-kami.vue')
      },      

      {
        path: '/event',
        name: 'Event',
        component: () => import('../views/pages/event/list.vue')
      }, 
      {
        path: '/visa',
        name: 'Visa',
        component: () => import('../views/pages/visa.vue')
      },
      
      {
        path: '/list-tour',
        name: 'List Tour',
        component: () => import('../views/pages/tour/list.vue')
      },
      {
        path: '/tour/:slug',
        name: 'Detail Tour',
        component: () => import('../views/pages/tour/detail.vue')
      },
      {
        path: '/list-all-Tour',
        name: 'See All Tour',
        component: () => import('../views/pages/tour/list-all.vue')
      },
      // {
      //   path: '/package-umroh',
      //   name: 'List Tour',
      //   component: () => import('../views/pages/product/list.vue')
      // },
      {
        path: '/paket-umroh',
        name: 'umroh',
        component: () => import('../views/pages/product/list-all.vue')
      },
      {
        path: '/paket-umroh/detail/:slug/:date_depart',
        name: 'Detail Umroh',
        component: () => import('../views/pages/product/detail.vue')
      },
      {
        path: '/event/detail-event',
        name: 'Detail-Event',
        component: () => import('../views/pages/event/detail-event.vue')
      },

      {
        path: '/berita/detail/:slug',
        name: 'detail ex 1',
        component: () => import('../views/pages/berita/detail.vue')
      },
       
      {
        path: '/haji/:slug',
        name: 'detail haji',
        component: () => import('../views/pages/haji/detail-haji.vue')
      },
       
      {
        path: '/transaksi',
        name: 'Transaksi',
        component: () => import('../views/pages/transaksi/list.vue')
      },
       
      {
        path: '/transaksi/detail/:id',
        name: 'Transaksi Detail',
        component: () => import('../views/pages/transaksi/detail.vue')
      },
       
      {
        path: '/tabungan',
        name: 'Tabungan',
        component: () => import('../views/pages/tabungan/form.vue')
      },
      {
        path: '/promo-tabungan',
        name: 'Promo Tabungan',
        component: () => import('../views/pages/tabungan/promo.vue')
      },
       
      {
        path: '/setting',
        name: 'Setting',
        component: () => import('../views/pages/setting_account.vue')
      },

      {
        path: '/cabang/:cabang',
        name: 'cabang',
        component: () => import('../views/pages/cabang/cabang.vue')
      },      
    ]},
    {
      path: '',
      component: () => import('@/views/full-page/full-pages.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/full-page/register.vue'),
          meta: {
            guest: true
          }
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/full-page/login.vue'),
          meta: {
            guest: true
          }
        },
        {
          path: '/not-found',
          name: 'notfound',
          component: () => import('@/views/full-page/not-found.vue')

        }
      ]
    },
  ]
})
// router.beforeEach((to, from, next) => {
  // if (to.matched.some(record => record.meta.requireAuth)) {
  //   if (store.state.userdata == null) {
  //     next('/')
  //   }
  // }
  // if (to.matched.some(record => record.meta.guest)) {
  //   // this route check if user have logged in redirect to home page
  //   if (store.state.userdata != null) {
  //     next('/')
  //   }
  // }
  // next('/')
// })

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
export default router
